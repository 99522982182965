<template>
  <v-card :width="calculateWidth" :loading="loadingLoginButton">
    <v-main fluid class="fill-height pa-4">
      <v-row>
        <v-col lg="6" md="12" class="d-flex align-center justify-center">
          <div class="w-100 mx-8">
            <div
              class="text-h4 font-weight-bold"
              data-testid="test-login-title"
            >
              {{ $t("login_to_your_account") }}
            </div>

            <v-card-text class="mt-6">
              <v-form v-model="valid">
                <v-text-field
                  type="email"
                  prepend-icon="mdi-email"
                  v-model="email"
                  :rules="[
                    (v: string) =>
                      /^[^\s@]+@[^\s@]+$/.test(v) || $t('invalidEmail'),
                  ]"
                  :error-messages="errorMessages"
                  label="E-mail"
                  :placeholder="$t('placeholderEmail')"
                  required
                  data-testid="test-input-email"
                  variant="outlined"
                  :disabled="loadingLoginButton"
                  :loading="loadingLoginButton"
                />

                <v-text-field
                  :type="showPassword ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  v-model="password"
                  :rules="[() => !!password || $t('fieldRequired')]"
                  label="Password"
                  placeholder="*********"
                  required
                  data-testid="test-input-password"
                  variant="outlined"
                  @click:append-inner="togglePasswordVisibility"
                  :disabled="loadingLoginButton"
                  :loading="loadingLoginButton"
                />
                <TheButton
                  :loading="loadingLoginButton"
                  :color="'primary'"
                  :size="'large'"
                  @clicked-button="submitLogin"
                  class="mt-2"
                  :rounded="'lg'"
                  :elevation="0"
                  :title="$t('login')"
                  data-testid="test-login-button"
                  :is-disabled="loadingLoginButton"
                />
              </v-form>
            </v-card-text>

            <TheErrorBox class="ml-15 mt-2" :title="loginErrorMessage" />

            <TheMainText
              @click="changeFormView"
              :clickable="true"
              :title="$t('forgotPassword')"
              data-testid="test-forgot-password"
            />
          </div>
        </v-col>
        <v-col v-if="!$vuetify.display.mdAndDown" cols="6">
          <div class="d-flex align-center justify-center image-container">
            <v-img
              :src="ConrooConnectIllustration"
              cover
              max-height="100%"
              max-width="100%"
            />
          </div>
        </v-col>
      </v-row>
    </v-main>
  </v-card>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import TheButton from "@/components/TheButton.vue";
import TheMainText from "@/components/TheMainText.vue";
import TheErrorBox from "@/components/TheErrorBox.vue";
import ConrooConnectIllustration from "@/assets/main/conroo-illustration-connect.svg";
import { useDisplay } from "vuetify";

defineProps({
  errorMessages: {
    type: String,
    required: false,
    default: "",
  },
  loginErrorMessage: {
    type: String,
    required: false,
    default: "",
  },
  loadingLoginButton: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["submit-login", "changed-login-view"]);
const { name } = useDisplay();

const valid = ref(false);
const email = ref("");
const password = ref("");
const showPassword = ref(false);

// methods
const submitLogin = async (): Promise<void> => {
  emit("submit-login", {
    email: email.value,
    password: password.value,
    validate: valid.value,
  });
};

const changeFormView = (): void => {
  emit("changed-login-view");
};

const togglePasswordVisibility = (): void => {
  showPassword.value = !showPassword.value;
};

const calculateWidth = computed(() => {
  switch (name.value) {
    case "xl":
      return "50vw";
    case "lg":
      return "60vw";
    case "md":
      return "60vw";
    case "sm":
      return "80vw";
    case "xs":
      return "90vw";
    default:
      return "100vw";
  }
});
</script>

<style lang="css">
/*
   This disables Chrome filling the fields with color after you
   apply saved data like email & password.
*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;
}

.image-container {
  width: 100%;
  height: 100%;
}
</style>
